import { useCallback } from 'react';

import { useSelector } from 'react-redux';

// CONTEXTS
import { usePriceContext } from 'contexts/priceContext';

// HOOKS
import { formatNumberDecimal } from 'utils/numberUtil';

// SELECTORS
import { ECurrency } from 'constants/currency';
import { selectUserHome } from 'store/userData/userDataSelectors';

const usePrice = () => {
  const exchangeRateVnd = 1000000; // CHANGE TO UTIL
  const { currencyList, exchangeRate, preferCurrency, localeCode } =
    usePriceContext();
  const { projectCurrency } = useSelector(selectUserHome);

  // CHANGE TO UTIL
  const symbolIcon = useCallback(
    (currency = ECurrency.Usd) => {
      const symbolData = currencyList.find((item) => item.code === currency);
      return symbolData ? symbolData?.symbol : '';
    },
    [currencyList]
  );

  const exChangePrice = (
    from = ECurrency.Usd,
    to = ECurrency.Vnd,
    priceToChange: number
  ) => {
    let newPrice = priceToChange;
    if (from === ECurrency.Usd && to === ECurrency.Vnd) {
      newPrice = priceToChange * exchangeRate;
    }
    if (from === ECurrency.Vnd && to === ECurrency.Usd) {
      newPrice = priceToChange / exchangeRate;
    }

    return newPrice;
  };

  const exchangePriceToPreferCurrency = (
    currency: ECurrency,
    priceToChange: number,
    currencyFormat?: 'prefix' | 'suffix'
  ) => {
    const newPrice = exChangePrice(currency, preferCurrency, priceToChange);
    const symbol = symbolIcon(preferCurrency);

    switch (currencyFormat) {
      case 'prefix':
        return `${symbol}${formatNumberDecimal(newPrice)}`;
      case 'suffix':
        return `${formatNumberDecimal(newPrice)} ${symbol}`;
      default:
        return newPrice;
    }
  };

  const exchangePriceWithCurrency = (
    price: number,
    currencyFormat?: 'prefix' | 'suffix',
    showPlus?: boolean,
  ) => {
    const symbol = symbolIcon(preferCurrency);

    switch (currencyFormat) {
      case 'prefix':
        return `${symbol} ${formatNumberDecimal(price)}${showPlus ? '+' : ''}`;
      case 'suffix':
        return `${formatNumberDecimal(price)}${showPlus ? '+' : ''} ${symbol}`;
      default:
        return price;
    }
  };

  const exchangeTooltipPrice = (priceInput: number): number => {
    let priceToChange = 0;
    if (!projectCurrency) {
      if (preferCurrency === ECurrency.Vnd) {
        priceToChange = priceInput;
      }
    } else {
      if (projectCurrency?.code === preferCurrency) {
        priceToChange = priceInput;
      } else {
        if (projectCurrency?.code === ECurrency.Usd) {
          priceToChange = exChangePrice(
            ECurrency.Usd,
            ECurrency.Vnd,
            +priceInput
          );
        }
        if (projectCurrency?.code === ECurrency.Vnd) {
          priceToChange = exChangePrice(
            ECurrency.Vnd,
            ECurrency.Usd,
            +priceInput
          );
        }
      }
    }
    return priceToChange;
  };
  // Price is VND: Display unit million
  const handleShowPrice = (price: number): number => {
    if (projectCurrency) {
      return projectCurrency?.code === ECurrency.Vnd
        ? price / exchangeRateVnd
        : price;
    } else {
      // Because BE return currencyPrice VND so we will temp change to VND
      return preferCurrency === ECurrency.Usd ? price / exchangeRateVnd : price;
      // return preferCurrency === ECurrency.Vnd ? price / exchangeRateVnd : price;
    }
  };

  const fullPriceCurrency = (
    value: number,
    currency = ECurrency.Usd
  ): string => {
    return `${symbolIcon(currency)} ${formatNumberDecimal(value, localeCode)}`;
  };

  return {
    exChangePrice,
    exchangePriceToPreferCurrency,
    exchangePriceWithCurrency,
    exchangeTooltipPrice,
    handleShowPrice,
    symbolIcon,
    fullPriceCurrency,
  };
};

export default usePrice;
